import { default as indexp9V2p5fxycMeta } from "~/pages/index.vue?macro=true";
import { default as applicationsF8LTZw225NMeta } from "~/pages/applications/applications.vue?macro=true";
import { default as fileDownloadsrE1HYfNbOkMeta } from "~/pages/applications/fileDownloads.vue?macro=true";
import { default as settingsvGzNDEhPAcMeta } from "~/pages/settings/settings.vue?macro=true";
export default [
  {
    name: "home",
    path: "/",
    meta: {"middleware":["init"]},
    component: () => import("~/pages/index.vue")
  },
  {
    name: "application-page",
    path: "/applications",
    meta: {"middleware":["init"]},
    component: () => import("~/pages/applications/applications.vue")
  },
  {
    name: "file-downloads",
    path: "/applications/documents",
    meta: {"middleware":["init"]},
    component: () => import("~/pages/applications/fileDownloads.vue")
  },
  {
    name: "settings-page",
    path: "/settings",
    meta: {"middleware":["init"]},
    component: () => import("~/pages/settings/settings.vue")
  }
]